@import '/src/styles/global.scss';

$color-background-nav: rgba(#1d6f95, 0.95);

.nav-link {
  .title {
    min-width: 100px;
    position: relative;
    text-decoration: none;
    color: $color-text-light;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1em;
    height: 100%;
    width: 100%;

    &:hover {
      background-color: $color-accent;
    }
  }

  .selected {
    // : 1em;
    position: relative;
    background-color: rgba($color-primary, 0.7);
    box-shadow: 5px 5px 5px #3d3d3d;
    z-index: 11;
    transform: translate(-3px, 0.5px) scale(0.99);

    // transform:
    transition: all 0.5s ease;
  }

  .sub-dropdown {
    position: absolute;
    padding: 0.5em 1em;
    // display: none;
    display: none;
    flex-direction: column;
    gap: 0.5rem;
    // min-width: fit-content;
    z-index: 0;
    background-color: $color-background-nav;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    top: 4rem;
    left: 0;
    right: 0;
    // text-align: center;

    li {
      > * {
        color: $color-text-light;
        font-weight: 400;
        font-size: 0.85rem;
      }

      &:not(:last-child)::after {
        content: '';
        display: block;
        height: 1px;
        background-color: $color-white;
        margin-top: 10px;
      }

      &:hover {
        text-decoration: underline;
        text-decoration-color: $color-white;
      }
    }

    &.show {
      display: flex;
    }
  }
}
