@import '/src/styles/global.scss';

.home-layout {
  display: flex;
  height: 100%;
  width: 100%;

  &__content {
    flex: 1;
    padding: $padding-page;
  }
}
