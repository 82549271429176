@import '/src/styles/global.scss';


.bdc-layout {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr 200px;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'bdc-layout__banner bdc-layout__banner'
    'bdc-layout__outlet bdc-layout__drawer';
}

.bdc-layout__banner {
  grid-area: bdc-layout__banner;

  .bdc-banner {
    width: 100%;
    display: block;
  }
}

.bdc-layout__outlet {
  padding: 2rem 1rem 4rem 1rem;
  grid-area: bdc-layout__outlet;
}

.bdc-layout__drawer {
  grid-area: bdc-layout__drawer;
}
