@import '/src/styles/global.scss';

.pagination-modal {
  @include modal(350px, 300px);
  background-color: $color-white;
  box-shadow: $box-shadow-card;

  .pagination-container {
    height: 100%;
    width: 100%;
    background-color: $color-white;

    header {
      padding: 15px;
      background-color: rgba($color-primary, 0.8);
      color: $color-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;

      h3 {
        text-align: center;
        color: $color-white;
      }

      span {
        font-size: 0.75em;
      }
    }

    .pagination-table {
      padding-top: 1em;
      display: flex;
      justify-content: center;

      .MuiTableCell-root {
        color: rgba($color-primary, 0.8);
        border-bottom: 1px solid rgba($color-primary, 0.2);
        font-size: 0.75em;
      }

      .MuiTableHead-root {
        .MuiTableCell-root {
          background-color: rgba($color-primary, 0.8);
          color: $color-white;
          font-weight: 600;
        }
      }
    }
    .pagination-error {
      padding: 15px;
      font-size: 0.75em;
      text-align: center;
      color: rgba($color-primary, 0.8);
    }
  }

  ::-webkit-scrollbar-track {
    background-color: (rgba($color-black, 0.2));
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba($color-primary, 0.5);
  }
}
