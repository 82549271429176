@import '/src/styles/global.scss';

$color-title-text: #000;
$color-title-bar: $color-primary;

.mdg-option {
  border-radius: 5px;
  flex-basis: 200px;
  height: 200px;
  margin: 10px;
  box-shadow: 0 5px 5px #3d3d3d;
  overflow: hidden;
  cursor: pointer;
  font-size: 2rem;




  .mdg-option-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

  }

  .mdg-option-text {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    will-change: transform;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }


}
.mdg-option-dsr-7 { // JARDIN
  background-color: $color-accent;
  a {
    color: $color-white;
  }
}

.mdg-option-dsr-8 { // ANIMAUX
  background-color: $color-complementary;
  a {
    color: $color-white;
  }
}
.mdg-option-dsr-9 { // ELEVAGE
  background-color: $color-secondary;
  a {
    color: $color-white;
  }
}
.mdg-option-dsr-10 { // TN
  background-color: $color-primary-variant;
  a {
    color: $color-white;
  }
}