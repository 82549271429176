@import '/src/styles/global.scss';

.contrat-detail {
  .title {
    @include title-horrizontal-bar($color-primary, $color-secondary);
  }

  .legend {
    text-align: center;
    .stars {
      color: $color-primary;
      vertical-align: sub;
    }
  }

  .section-title {
    color: $color-text-title;
    margin-bottom: 1rem;
  }

  .section-wrap {
    &:not(.contrat_detail__fournisseur) {
      max-width: fit-content;
    }
    width: 100%;
  }

  .contrat_detail__fournisseur {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.25rem;
    align-self: stretch;

    > * {
      flex: 1 1 350px;
      display: flex;
      flex-direction: column;
      font-size: 0.85rem;
      max-width: 350px;

      > li {
        padding: 0.25rem 1rem;
      }
    }

    .contrat-detail-info {
      > li {
        background-color: $color-primary-30;

        &:nth-child(2n) {
          background-color: $color-primary-10;
        }
        &:last-child {
          text-align: center;
          display: flex;
          justify-content: center;

          img {
            display: block;
            max-width: 150px;
            max-height: 150px;
          }
        }
      }
    }

    .contrat-detail-chrono {
      background-color: $color-primary-10;
      > li {
        &:first-child {
          font-weight: 500;
          text-align: center;
          margin-bottom: 0.5rem;
          color: $color-primary;
          font-size: 1rem;
        }
      }
    }
  }
}

.contrat-detail__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
