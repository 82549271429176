@import '/src/styles/global.scss';

.contrats-table {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  .button-color {
    color: $color-excel;
  }
  .color_xls {
    color: $color-excel;
  }
  .favoris{
    color: $color-secondary;
  }
  .nofavoris{
    color:$color-grey;
  }
}
