@import '/src/styles/global.scss';

.cl-smsbdd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: fit-content;
  gap: 1em;
  width: 75vw;
  max-width: 450px;
  .align-field {
    display: flex;
    gap: 1em;
  }
  .action-btn {
    @include flex-center();
  }
}
