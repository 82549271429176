@import '/src/styles/global.scss';

$color-title-text: $color-black;
$color-title-bar: $color-primary;

.solution {
  padding: $padding-page;
  display: flex;
  flex-direction: column;
  align-items: center;

  .solution-content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: 5rem;
  }

  .solution-map {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .solution-title {
    @include title-horrizontal-bar(
      $color-title-text,
      $color-title-bar
    );
  }

  .solution-image {
    align-self: center;
    width: 100%;
    max-width: 750px;
  }
}
