@import '/src/styles/global.scss';

.checkbox {
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.05em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;

    &::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $color-primary;
      background-color: CanvasText;
      transform-origin: bottom left;
      clip-path: polygon(
        14% 44%,
        0 65%,
        50% 100%,
        100% 16%,
        80% 0%,
        43% 62%
      );
    }

    &:focus {
      outline: max(1px, 0.05em) solid currentColor;
      outline-offset: max(2px, 0.15em);
    }

    &:checked::before {
      transform: scale(1);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}
