@import '/src/styles/global.scss';

$background-color: transparent;
@mixin color-modal($color, $color-background) {
  color: $color;

  .modal__head {
    background-color: $color-background;
  }
  .modal__body {
    background-color: $color-background;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  position: relative;
  top: 0;
  min-width: 350px;
  max-width: 85vw;
  max-height: 85vh;
  height: fit-content;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  background-color: $color-white;
  border-radius: 4px;

  .color-primary {
    @include color-modal($color-secondary, $color-white);
  }

  .modal-exit {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    z-index: 10;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    font-size: 20px;
    background-color: $color-white;
    color: $color-black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// .modal__head {
//   // position: relative;
//   padding: 0.5em;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   .modal-title {
//     width: 100%;
//     text-align: center;
//     font-weight: 500;
//     text-transform: uppercase;
//     font-size: 1.15em;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 0.75em;
//   }

//   .modal-exit {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 0.25em;
//   }

//   .modal-exit-wrapper {
//     position: absolute;
//     top: -1rem;
//     right: -1rem;
//     background-color: red;
//     border-radius: 9999px;
//   }
// }

.modal__body {
  position: relative;
  width: 100%;
  height: 100%;
}

// .primary-modal-bis {
//   @include color-modal($color-primary-btn, $color-white);
// }
// .primary-transparent-modal {
//   color: $color-primary;

//   .modal__head {
//     background-color: $color-primary-variant;
//   }
//   .modal__body {
//     border: none;
//   }
// }
// .secondary-modal {
//   @include color-modal($color-white, $color-primary);
// }
// .danger-modal {
//   @include color-modal($color-danger, $color-white);
// }
// .warning-modal {
//   @include color-modal($color-warning, $color-white);
// }
// .excel-modal {
//   @include color-modal($color-excel, $color-white);
// }
// .excel-variant-modal {
//   @include color-modal($color-white, $color-excel);
// }
