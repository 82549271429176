@import '/src/styles/global.scss';


$color-btn-text: $color-excel;

.excel-command-btn {
  padding: 0.75em 2.5em;
  cursor: pointer;
  font-size: 12px;

  @include btn(
    $color-btn-text,
    transparent,
    $color-white,
    $color-btn-text
  );
  border-color: $color-btn-text;

  svg {
    height: 1.5em;
    width: fit-content;
  }
  padding-left: 1em;
  padding-right: 1em;
}
