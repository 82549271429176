@import '/src/styles/global.scss';
.plano-card {
  font-size: 0.85em;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: fit-content;
  // width: 50%;
  height: 100%;
  border-radius: 5px;
  margin: 10px auto 10px auto;
  box-shadow: 0 5px 5px #8d8d8d;
  // border: 1px solid $color-accent;
  box-sizing: border-box;
  overflow: hidden;

  .plano-card-title {
    padding: 0.5em 1em;
    width: 100%;
    font-size: 1.5em;
    color: $color-text-light;
    background-color: $color-primary;
  }

  .plano-card-sub-title {
    // margin-bottom: 0.5em;
    width: 100%;
    background-color: rgba($color-primary, 0.3);
    text-align: center;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 500;
  }

  .plano-card-info {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    li {
      svg {
        vertical-align: middle;
      }
      a {
        color: $color-complementary;
      }
    }
  }
}

.plano-card__header {
  text-align: center;
}
.plano-card__section {
  padding: 1em;
  flex: 1;
  text-align: left;
  border-top: 1px solid $color-primary;
  .plano-card__section-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    .plano-card-photo {
      max-width: 100%;
    }
  }
}
