.app-layout {
  min-height: 100vh;
  width: 100vw;
  height: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0 1fr 150px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'app-layout__nav'
    'app-layout__content'
    'app-layout__footer';
}

.app-layout__nav {
  grid-area: app-layout__nav;
}

.app-layout__content {
  grid-area: app-layout__content;
  min-height: 100vh;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  height: fit-content;
}

.app-layout__footer {
  grid-area: app-layout__footer;
}
