@import '/src/styles/global.scss';

$color-primary: rgba($color-primary, 0.8);
$color-background: rgba($color-secondary, 0.4);
$size-bezel: 0.5em;
$size-radius: 4px;
$glass: rgba(255, 255, 255, 0.3);

.command-fourn-recap__container {
  width: 100%;
  height: 100%;

  .command-table__wrap {
    margin: 3rem 0 0 0;
    max-height: 80vh;
    overflow: auto;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }

  .command-action {
    margin-top: 1rem;
    margin-bottom: 5rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    height: fit-content;
  }
  .command-total-price {
    // background-color: blue;
    padding: 0.75em 1em;
    font-size: 12px;
    height: 100%;
    width: fit-content;
    min-width: 250px;
    text-align: right;
    // font-size: 0.85em;
    border-radius: 4px;

    background-color: $color-primary;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    text-align: center;
    border: 1px solid $color-primary;

    span {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  // ----------------------------------------------- FORM -----------------------------------------------
  .MuiFormControl-root {
    display: flex;
    margin-top: 1rem;

    .form-content {
      display: flex;
      gap: 1.5rem;
    }

    .input-comment {
      width: 25rem;
    }
  }
  // ----------------------------------------------- BUTTON -----------------------------------------------

  .button-list {
    justify-content: flex-end;
  }

  .command-fourn-recap__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .prospectus-title {
      @include title-horrizontal-bar(
        $color-primary,
        $color-secondary
      );
    }
    .prospectus-title__date {
      font-size: 0.6em;
      color: rgba($color-primary, 0.8);
    }
  }
  .command-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 2em 2em;
    grid-auto-flow: row;
    grid-template-areas:
      'command-form__commentary command-form__liv'
      'command-form__commentary command-form__fact'
      'command-form__commentary command-form__sem';
    width: 50vw;

    .input {
      @include input-with-label(
        $size-bezel,
        $size-radius,
        $color-primary,
        $color-primary,
        $color-white
      );
      font-size: 0.75em;
      &__field {
        width: 100%;
        min-width: 200px;
        height: 40px;
      }
    }

    .text-area {
      font-size: 0.75em;
      @include flex-center();
      @include textarea-with-label(
        $size-bezel,
        $size-radius,
        $color-primary,
        $color-primary,
        $color-white
      );
      &__field {
        width: 100%;
        min-width: 200px;
      }
    }
  }
  .information-title {
    h3 {
      @include title-horrizontal-bar(
        $color-primary,
        $color-secondary
      );
    }
  }

  .command-table__footer {
    @include flex-center();
    margin-bottom: 5rem;
  }

  .command-form__commentary {
    grid-area: command-form__commentary;
  }

  .command-form__liv {
    grid-area: command-form__liv;
  }

  .command-form__fact {
    grid-area: command-form__fact;
  }

  .command-form__sem {
    grid-area: command-form__sem;
  }

  ::-webkit-scrollbar-track {
    background-color: (rgba($color-primary, 0.4));
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: (rgba($color-primary, 0.7));
  }
}
