@import '/src/styles/global.scss';

.condition-table-wrap {
  display: flex;
  justify-content: center;
  font-size: 0.75em;
}

.condition-table__head {
  background-color: rgba($color-accent, 0.8);
  color: $color-white;
  font-weight: 600;
}

.condition-table__body {
  color: rgba($color-primary, 0.8);
  border-bottom: 1px solid rgba($color-primary, 0.2);
  font-size: 1em;
  background-color: rgba(255, 255, 255, 0.3);
}

.condition-table__head-row {
  padding: 0.5em 1em;
  //   min-width: 70px;
  text-align: center;
}

.condition-table__body-row {
  padding: 0.5em 1em;
  text-align: right;
}

.condition-table {
  border-radius: 4px;

  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-collapse: collapse;
}
