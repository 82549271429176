@import '/src/styles/global.scss';

$color-title-text: #000;
$color-title-bar: $color-primary;
.ae-select-opt__title{
  width: 100%;
  @include title-horrizontal-bar($color-text, $color-primary);
}

.ae-select-opt__content {
  padding: 1rem 1rem 4rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;


  .ae-select-opt__option-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    gap: 1rem 4rem;

    .ae-option {
      border-radius: 5px;
      flex-basis: 200px;
      height: 200px;
      margin: 10px;
      box-shadow: 0 5px 5px #3d3d3d;
      overflow: hidden;
      cursor: pointer;

      .ae-option-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      .ae-option-text {
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        will-change: transform;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
      }
    }

    .ae-option--green {
      background-color: $color-accent;
      span {
        color: $color-black;
      }
    }

    .ae-option--blue {
      background-color: $color-primary;
      span {
        color: $color-white;
      }
    }
  }
}
