@import '/src/styles/global.scss';

.update-password-form {
  padding: 1em;
  height: fit-content;
  min-width: 300px;
  width: fit-content;
  border: 2px solid rgba($color-primary, 0.2);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 2em 2em;
  grid-auto-flow: row;
  grid-template-areas:
    'title title'
    'user user'
    'form form-info'
    'form form-info';

  .title {
    grid-area: title;
    text-transform: uppercase;
    font-size: 1.1em;
    color: $color-primary;
    font-weight: 700;
    text-align: center;
  }

  .form-info {
    position: relative;
    grid-area: form-info;
    display: flex;
    flex-direction: column;
    gap: 1em;
    color: $color-primary;
    font-size: 0.85em;

    h5 {
      font-weight: 700;
    }

    ul {
      position: relative;
      left: 1.5em;
      display: flex;
      flex-direction: column;
      list-style-type: '- ';
    }
  }

  .form {
    grid-area: form;
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 2em;

    .form__inputs {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    .error-form {
      margin-top: 1em;
      margin-bottom: -1em;
      color: $color-danger;
      font-weight: 700;
      font-size: 0.85em;
      display: flex;
      justify-content: center;
    }

    .form__action {
      display: flex;
      justify-content: flex-end;
    }
  }
}
