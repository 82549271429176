@import '/src/styles/global';

.validation-mail-modal-wrap {
  position: relative;
  // min-height: 400px;
  display: flex;
  flex-direction: column;

  .validation-mail-modal-bg {
    position: relative;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    height: 150px;
    max-height: 150px;
    width: 100%;
    background-color: rgba($color-primary, 0.2);
    display: flex;
    justify-content: center;

    .bg-image {
      position: relative;
      height: 100%;
      width: 100%;
      max-width: 350px;
      background-image: url('./mail-img.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: scale(0.5);
    }
  }
  .validation-mail-modal {
    position: relative;
    margin-top: -1rem;
    flex: 1;
    width: 100%;
    background-color: $color-white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
  }

  .validation-mail-modal__title {
    padding: 0.5em;
    font-weight: 400;
    font-size: 1.25em;
    text-transform: uppercase;
    border-radius: 20px;
    text-align: center;
    color: $color-text;
  }
  .validation-mail-modal__content {
    padding: 0 1em;
    width: 100%;
    flex: 1;
  }
  .validation-mail-modal__action {
    margin-top: 1em;
    padding: 1em;
    display: flex;
    justify-content: center;
    gap: 0.5em;
  }
}
