@import '/src/styles/global.scss';

.profil-form {
  padding: 1em;
  width: fit-content;
  border: 2px solid rgba($color-primary, 0.2);
  border-radius: 4px;

  .title {
    grid-area: title;
    text-transform: uppercase;
    font-size: 1.1em;
    color: $color-primary;
    font-weight: 700;
    text-align: center;
  }

  .form-info {
    position: relative;
    grid-area: form-info;
    display: flex;
    flex-direction: column;
    gap: 1em;
    color: $color-primary;
    font-size: 0.85em;

    input {
      width: 500px;
    }
    h5 {
      font-weight: 700;
    }
  }
}
