@import '/src/styles/global.scss';

$color-primary: $color-text;
$color-background: rgba($color-secondary, 0.4);
$size-bezel: 0.5em;
$size-radius: 4px;
$glass: rgba(255, 255, 255, 0.3);

.cl-devis-layout {
  position: relative;
  z-index: 0;
  min-height: 100vh;
  min-width: 100%;
  font-size: 0.8em;

  .cl-devis__title {
    @include title-horrizontal-bar($color-text, $color-secondary);
  }

  .cl-devis__main {
    @include flex-column-center();
    gap: 2em;

    .warning {
      color: $color-danger;
      font-weight: 500;
      text-align: justify;
      max-width: 600px;
    }
  }

  .cl-devis__form {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
    gap: 1em;
    width: 75vw;
    max-width: 450px;
  }
}
