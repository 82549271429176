@import '/src/styles/global';

$radius: 4px;

@mixin color-btn(
  $color-background,
  $color-text,
  $color-background-hover,
  $color-text-hover
) {
  span {
    color: $color-text;
  }
  background: $color-background;
  border: 1px solid $color-text;

  &:hover:not([disabled]) {
    background-color: $color-background-hover;
    span {
      color: $color-text-hover;
    }
  }
}

.button {
  position: relative;
  max-height: 1.5em;
  width: fit-content;
  padding: 1.25em 1em;
  border-radius: $radius;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  overflow: hidden;
  cursor: pointer;
  span {
    font-size: 0.9em;
  }
  &[disabled] {
    box-shadow: inset 0 0 10rem rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }

  &.color-primary {
    @include color-btn(
      $color-button,
      $color-text-light,
      $color-button,
      $color-text-light
    );
  }

  &.color-secondary {
    @include color-btn(
      $color-white,
      $color-primary,
      rgba($color-primary, 0.2),
      $color-primary
    );
  }

  &.color-neutral {
    @include color-btn(
      $color-white,
      $color-accent,
      rgba($color-black, 0.01),
      $color-accent
    );
  }

  &.color-button {
    @include color-btn(
      $color-button,
      $color-white,
      $color-button-hover,
      $color-white
    );
  }

  &.color-danger {
    @include color-btn(
      $color-white,
      $color-danger,
      $color-danger,
      $color-white
    );
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    z-index: 2;
    transition: 0.5s ease-in-out;
    white-space: wrap;
    opacity: 1;
    transition: opacity 0s ease-in-out;
  }

  .loading {
    opacity: 0;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
