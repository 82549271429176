@import '/src/styles/global.scss';

.accueil-carousel-wrap {
  display: flex;
  justify-content: center;
  width: 100vw;
  box-sizing: border-box;

  .banner {
    width: 100%;
    display: block;
  }
}
