@import '/src/styles/global.scss';

.page-banner-layout {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'page-banner-layout__banner'
    'page-banner-layout__outlet';
  min-height: 100vh;
  width: 100%;
}

.page-banner-layout__banner {
  grid-area: page-banner-layout__banner;
  overflow: hidden;

  .page-banner {
    width: 100%;
    display: block;
  }
}

.page-banner-layout__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  img {
    height: 350px;
    filter: opacity(10%);
  }
}

.page-banner-layout__outlet {
  position: relative;
  grid-area: page-banner-layout__outlet;
  height: 100%;
  width: 100%;
  box-sizing: border-box; /* Ajout de la propriété box-sizing */
  min-width: 100%; /* Ajout de la propriété min-width */
}
