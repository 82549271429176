@import '/src/styles/global.scss';

$bg: $color-background;

.loader-page {
  width: 100%;
  min-height: calc(100vh - 150px);
  background-color: $bg;
  display: flex;
  justify-content: center;
  align-items: center;
}
