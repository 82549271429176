@import '/src/styles/global.scss';

$color-title-text: $color-black;
$color-title-bar: $color-primary;

.ae-section {
  .ae-section-title {
    @include title-horrizontal-bar(
                    $color-title-text,
                    $color-title-bar
    );
  }
  .ae-section-prestataires {
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, max(350px));

    grid-gap: 6em 2em;
    justify-content: center;
    max-width: 100%;

    .ae-section-prestataires-card-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}