@import '/src/styles/global.scss';

.up-layout {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 100vw;

  &__content {
    flex: 1;
    padding: $padding-page;
    overflow: hidden;
  }
}
