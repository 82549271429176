@import '/src/styles/global.scss';

.contact-card{
  .contact-card-titre{
    text-transform: uppercase;
    font-size: $font-size-title;
    color: $color-primary;
  }



}