@import '/src/styles/global.scss';

.select-magasin-list-wrapper {
  max-height: 100%;
  height: 100%;
  width: 100%;
  min-width: 350px;
  max-width: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .select-magasin-input {
    display: flex;
    justify-content: center;
  }

  .select-magasin-list {
    position: relative;
    overflow: auto;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 10px; /* largeur de la scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: $color-primary-variant; /* couleur de fond de la scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary; /* couleur du "pouce" de la scrollbar */
      border-radius: 5px; /* arrondi des coins du "pouce" */
    }
  }
  .select-magasin-list__head {
    position: sticky;
    top: 0;
    padding: 0.05rem 1rem;
    background-color: $color-primary;
    text-align: center;
    vertical-align: center;
    box-shadow: 0px 5px 4px rgba(29, 111, 149, 0.25);

    .title {
      color: $color-text-light;
      font-weight: 400;
    }
  }
  .select-magasin-list__list {
    position: relative;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 0.05rem;

    .select-mag-card {
      position: relative;
      padding: 0.25rem;
      z-index: -1;
      // display: grid;
      // grid-template-columns: 1fr 2fr;
      // grid-template-rows: 50px;
      // gap: 0px 0px;
      // grid-auto-flow: row;
      // grid-template-areas: 'mag-logo mag-info';

      min-width: 300px;
      width: 99%;

      box-shadow: 0px 1px 4px rgba(29, 111, 149, 0.25);
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 5px 2px $color-primary-variant;
      }

      &.selected {
        box-shadow: 0 0 5px 1px $color-primary;

        background-color: rgba($color-primary-variant, 0.3);
      }
    }

    .mag-logo {
      grid-area: mag-logo;
      height: 100%;
      max-width: 100px;
      position: relative;
    }

    .mag-info {
      grid-area: mag-info;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;

      .title {
        color: $color-text-title;
        font-weight: 500;
        text-transform: uppercase;
      }

      .sub-title {
        color: $color-text-muted;
        text-transform: capitalize;
        font-size: 0.8rem;
      }
    }
  }
  .select-magasin-list__action {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    gap: 1rem;
  }
}
