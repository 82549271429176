@import '/src/styles/global.scss';

$color-primary: rgba($color-primary, 0.8);
$size-bezel: 0.5em;
$size-radius: 4px;

.bdc-select-btn {
  .bdc-select {
    @include select-with-label(
      $size-bezel,
      $size-radius,
      $color-primary,
      $color-primary,
      $color-white
    );
    min-width: 120px;
  }
}
