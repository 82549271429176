@import '/src/styles/global.scss';

.basic-input {
  margin-top: 1.5em;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    padding: 0.5em 1em;
    background-color: rgba($color-white, 0.9);
    color: $color-text;
    border-bottom: 2px solid $color-primary;

    &:focus {
      outline: none;
      border-color: $color-white;
      background-color: rgba($color-white, 1);
    }
  }

  label {
    position: absolute;
    top: -1.5em;
    color: $color-white;
    font-weight: 700;
    font-size: 0.9em;
  }
  .error {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-danger;
    font-weight: 700;
    font-size: 0.75em;
  }
}
