@import '/src/styles/global.scss';

$nav-bar-height: 52px;

.hamburger {
  padding: 1em;
  color: $color-text-light;
  background-color: $color-accent;
  display: flex;
  justify-content: center;
  align-items: center;

  .ham-btn {
    height: fit-content;
  }
}

.hamburger--open {
  background-color: transparent;
}

.ham-nav {
  background-color: rgba(#1d6f95, 0.9);
  position: fixed;
  top: calc($nav-bar-height + 0.5rem);
  right: 0;
  width: 0;
  min-height: 50vh;
  overflow: hidden;
  z-index: 9;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .multiple-links {
    display: flex;
    flex-direction: column;
    > ul {
      margin-left: 4rem;
    }
  }
}

.ham-nav__menu {
  grid-area: ham-nav__menu;
}

.ham-nav__options {
  grid-area: ham-nav__options;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .log-btn {
    width: 100%;
    height: 2em;
    background-color: $color-accent;
    color: $color-text;
    text-transform: uppercase;
  }

  .user-options {
    width: 100%;
    padding: 1em;
    border-top: 1px rgba($color-text, 0.5) solid;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text-light;

    a,
    span {
      padding: 0.5em;
      color: $color-text-light;
      text-transform: uppercase;
      font-weight: 400;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ham-nav__link {
  display: flex;
  border-bottom: 1px rgba($color-text, 0.5) solid;

  a,
  button,
  span {
    padding: 0.5em;
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    white-space: nowrap;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.ham-nav.showMenu {
  width: 100%;
}
