@import '/src/styles/global.scss';

$img-height: 200px;

.book-list {
  position: relative;
  min-height: $img-height;
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
}
