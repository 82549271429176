@import '/src/styles/global.scss';

$color-primary: rgba($color-primary, 0.8);
$color-primary-variant: $color-white;
$color-background: rgba($color-secondary, 0.4);
$color-info: $color-danger;
$border-radius: $border-radius-card;
$box-shadow: $box-shadow-card;
$size-bezel: 0.5em;
$size-radius: 4px;

.product-card {
  display: grid;
  grid-template-columns: 250px 1fr 250px;
  grid-template-rows: 20px 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'product-card__title product-card__title product-card__title'
    'product-card__head product-card__body product-card__footer';

  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  background-color: $color-white;

  overflow: hidden;
  font-size: 0.75em;
  color: $color-primary;

  .product-card__title {
    grid-area: product-card__title;
    // background-color: $color-primary;
    display: flex;
    justify-content: center;
    text-align: center;

    h3 {
      font-size: 1rem;
      text-transform: uppercase;
      text-align: center;
      // color: $color-white;
    }
  }

  .product-card__head {
    grid-area: product-card__head;
    @include flex-column-center;
    padding: 1rem;

    h3 {
      padding: 0.5em;
      text-align: center;
      @include flex-center;
      font-size: 0.85em;
      font-weight: 500;
      color: $color-primary;
    }
  }

  .product-card__img {
    padding: 5px;
    width: 90%;
    height: 120px;
    @include flex-center;

    img {
      width: auto;
      height: 100%;
      border-radius: 0.5em;
    }
  }

  .product-card__body {
    position: static;
    grid-area: product-card__body;

    max-width: 600px;
    min-width: 200px;

    overflow-y: hidden;
    overflow-x: auto;
    font-size: 0.75rem;

    .product-info {
      position: static;
      padding: 0.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0.5rem 1rem;
      height: 100%;
      grid-auto-flow: row;
      grid-template-areas:
        'product-table product-table product-table'
        'product-reference product-conditioning product-commentary';
    }

    .product-info__table {
      grid-area: product-table;
      display: flex;
      align-items: center;

      .title {
        text-align: center;
      }
    }

    // .product-info__offer {
    //   grid-area: product-offer;
    //   font-size: 0.85em;
    // }

    .product-info__commentary {
      grid-area: product-commentary;
      margin-top: 1.5em;
      display: flex;
      gap: 1em;
      font-size: 0.85em;
    }

    .product-info__conditioning {
      grid-area: product-conditioning;
      margin-top: 1.5em;

      display: flex;
      flex-direction: column;

      font-size: 0.85em;
    }

    .product-info__reference {
      grid-area: product-reference;
      margin-top: 1.5em;
      font-size: 0.85em;

      display: flex;
      flex-direction: column;
      gap: 1em;

      .depliant {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        font-size: 0.75em;
        width: fit-content;
        // width: 15em;

        div {
          padding: 0.5em 1em;
          border-radius: 4px 4px 0 0;
          background-color: rgba($color-accent, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          color: $color-white;
          font-weight: 600;
          white-space: nowrap;
        }

        ul {
          padding: 0.5em 1em;
        }
      }
    }

    .text-icon {
      display: flex;
      align-items: center;
      gap: 0.5em;

      svg {
        font-size: 1.5em;
      }
    }
  }

  .product-card__footer {
    grid-area: product-card__footer;
    padding: 0.5em;

    .panier-detail {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 1em;
      grid-template-areas:
        'panier-detail__prix-uni'
        'panier-detail__quantity'
        'panier-detail__total';
    }

    .panier-detail__action {
      grid-area: panier-detail__action;
      @include flex-center;
      align-items: center;

      .MuiSvgIcon-root {
        font-size: 1.75rem;
      }
    }

    .panier-detail__quantity {
      grid-area: panier-detail__quantity;
      @include flex-center;
      align-items: center;

      .input {
        @include input-with-label(
          $size-bezel,
          $size-radius,
          $color-primary,
          $color-primary,
          $color-white
        );
        &__field {
          text-align: right;
          width: 100px;
        }
      }
    }
    .panier-detail__prix-uni {
      grid-area: panier-detail__prix-uni;
      @include flex-center;
      align-items: center;
      .select {
        @include select-with-label(
          $size-bezel,
          $size-radius,
          $color-primary,
          $color-primary,
          transparent
        );

        &__field {
          text-align: right;
          width: 100px;
        }

        .select__label {
          display: flex;
          flex-wrap: nowrap;
          transform: translate(0, -45%) scale(0.8);

          .label {
            display: flex;
            height: 30px;

            p {
              background-color: $color-white;
              height: 10px;
            }
          }
        }

        // span {
        //   transform: translate(0, -75%) scale(0.8);
        //   background-color: white;
        //   width: 65px;
        //   height: 40px;
        // }
      }
    }

    .panier-detail__total {
      grid-area: panier-detail__total;
      @include flex-center;
      align-items: center;
      gap: 1em;

      .total-price {
        border: 1px solid $color-primary;
        padding: calc($size-bezel * 1.5) $size-bezel;
        color: $color-white;
        background: $color-primary;
        border-radius: $size-radius;
        font-size: 1.25em;
        width: 100px;
        text-align: right;
      }

      // .MuiSvgIcon-root {
      //   border: 2px solid $color-primary;
      //   color: #ffebcc;
      //   background: $color-primary;
      //   border-radius: $size-radius;
      //   font-size: 2rem;
      //   cursor: pointer;
      // }
    }
  }

  ::-webkit-scrollbar-track {
    background-color: (rgba($color-white, 0.4));
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-primary;
  }

  .warning-icon {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: orange;
    cursor: help;
    background-color: transparent;
    transform: translate(-6em, +200%) scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .product-card {
    max-width: 85%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 20px 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'product-card__title product-card__title'
      'product-card__head  product-card__footer'
      'product-card__body product-card__body';
  }
}
