@import '/src/styles/global.scss';

.cl-mailing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: fit-content;
  gap: 1em;
  width: 75vw;
  max-width: 550px;

  .date-validite {
    display: flex;
    gap: 1em;
  }

  .action-btn {
    font-size: 1rem;
    @include flex-center();
  }
}
