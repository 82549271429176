@import '/src/styles/global.scss';

// $color-nav: #1d6f95;
$color-nav-hover: $color-accent;
// $color-link-hover: rgba(#ffb749, 0.8);
// $color-primary: white;
// $color-text: white;
// $color-primary-hover: white;
// $nav-bar-height: 52px;

//Colors variables
$color-background-nav: rgba(#1d6f95, 0.7);
$color-link: $color-white;
$color-link-hover: rgba(#ffb749, 0.8);

//sizes variables
$nav-height: 4rem;

.nav__logo {
  margin: 0 2em;
  position: relative;
  height: 40px;
  width: 40px;
}

.nav {
  position: fixed;
  z-index: 100;
  top: 0;
  // left: 0;
  right: 0;
  width: 50%;
  background-color: $color-background-nav;
  height: $nav-height;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    position: relative;
    margin: 0 2em;
    height: 40px;
    width: 40px;
  }

  &__links,
  &__actions {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__burger {
    display: none;
  }

  .dropdown {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;
    height: 100%;

    > li {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      min-width: 150px;
    }
  }
}

/* -------------------------------- SMALL DEVICES -------------------------------- */
/* Au niveau le plus haut du code */
@media screen and (max-width: 1024px) {
  .nav {
    width: 100%;
  }
  .nav__burger {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    min-height: $nav-height;
    width: 100%;
  }
  .nav__links,
  .nav__actions {
    display: none;
  }
}
