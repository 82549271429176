@import '/src/styles/global.scss';

.selection-prospectus__container {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  h3 {
    @include title-horrizontal-bar($color-primary, $color-secondary);
  }
  .prospectus__card_list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 2rem 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .prospectus__card_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}
