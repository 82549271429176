@import '/src/styles/global.scss';

$color-background: $color-white;

.btn-wrap {
  display: flex;
  gap: 1em;
}

.btn-delete {
  padding: 0.75em 2.5em;
  cursor: pointer;
  font-size: 12px;
  @include btn(
    $color-danger,
    transparent,
    $color-white,
    $color-danger
  );
  border-color: $color-danger;
}

.btn-primary {
  padding: 0.75em 2.5em;
  cursor: pointer;
  font-size: 12px;
  @include btn(
    $color-white,
    $color-primary,
    $color-white,
    $color-primary
  );
  border-color: $color-primary;
}

.delete-command-confirm-modal {
  @include modal(auto, 120px);
  background-color: $color-background;
  border: 1px solid $color-primary;
  // box-shadow: 0.5rem 0.5rem 0 rgba($color-primary, 0.5);
  // transform: translate(-50%, -65%);
}

.delete-command-confirm-modal__container {
  width: 100%;
  height: 100%;
  padding: 1em;
  @include flex-column-center();
  justify-content: space-between;
}

.delete-command-modal {
  @include modal(auto, 150px);
  background-color: $color-background;
  border: 1px solid $color-primary;
  // box-shadow: 0.5rem 0.5rem 0 rgba($color-primary, 0.5);
}

.delete-command_modal__text {
  font-size: 0.75em;
}

.delete-command_modal__container {
  width: 100%;
  height: 100%;
  padding: 1em;
  @include flex-column-center();
  justify-content: space-between;
}
