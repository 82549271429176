@import '/src/styles/global.scss';

.preco-four {
  display: flex;
  flex-direction: column;

  // gap: 2rem;
}
.preco-four-table-overlay {
  display: flex;
  flex-direction: column;

  gap: 1rem;
  align-self: center;
  max-width: 100%;

  > .filter {
    max-width: fit-content;
  }
}
.preco-four-table-wrap {
  max-width: 100%;
  max-height: 70vh;
  width: fit-content;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px; /* largeur de la scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: $color-primary-variant; /* couleur de fond de la scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary; /* couleur du "pouce" de la scrollbar */
    border-radius: 5px; /* arrondi des coins du "pouce" */
  }
}
.preco-four-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.65rem;
  border-radius: 4px;
  // overflow: hidden;
  //   width: fit-content;

  .first {
    width: 250px;
  }

  td {
    border: 1px solid $color-border-cell-table;
    border-bottom: none;
    padding: 0.1rem 1rem;

    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
  .low-row {
    width: 20%;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
    }
  }
  .centered-row {
    text-align: center;
  }
  .title-row {
    padding: 10px;
    background-color: $color-primary;
    color: $color-text-light;
    text-transform: uppercase;
    font-size: 0.85rem;
  }
  .color-row {
    background-color: $color-primary-10;
  }
  .img-mdg {
    height: 1rem;
  }
}
