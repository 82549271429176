@import '/src/styles/global.scss';

.chronos-list-wrapper {
  position: relative;
  padding: 0.5em 1em;
  border-radius: 16px;
  border: 1px solid $color-accent;
  max-width: 420px;
  min-height: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .chronos__head {
    display: flex;
    align-items: center;
    gap: 1rem;

    .inedis-logo {
      height: 30px;
    }
    > .title {
      line-height: 1.25;
      // font-size: 0.85em;
      text-transform: uppercase;
      text-align: center;
      // background-color: $color-primary;
      width: 100%;
      font-weight: 700;
      color: $color-primary;
      // color: $color-white;
    }
  }

  .chronos__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .chronos-search {
    display: flex;
    justify-content: center;
    font-size: 0.85rem;
  }

  .chronos-list {
    flex: 1;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.75rem;

    ul {
      color: $color-text-muted;
      li {
        display: flex;
        gap: 1.5em;

        a {
          all: inherit;
          cursor: pointer;

          &.blink {
            animation: blink 1.5s ease-in-out infinite;
          }
          @keyframes blink {
            0% {
              color: $color-danger;
            }
            50% {
              color: $color-text-muted;
            }
            100% {
              color: $color-danger;
            }
          }
        }
      }
    }
    .favoris {
      color: $color-secondary;
    }
  }

  .chronos-list__actions {
    display: flex;
    justify-content: flex-end;
    button {
      color: $color-primary;

      &[disabled] {
        color: $color-disabled;
      }
    }
  }
}
