@import '/src/styles/global.scss';

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-primary;
  background: $color-background;
  color: $color-text;
}

#root {
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

/* ---------------------------------- GENERAL STYLES  ---------------------------------- */
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  // color: $color-primary;
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
}

// Reset all default properties for buttons
button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

a {
  color: $color-primary-variant;
  // transition: $transition;
  outline: none;
}

.text-danger {
  color: $color-danger;
}

.text-info {
  color: $color-primary;
}

.text-small {
  font-size: 0.75em;
}

.info-note {
  font-size: 1.25em;
}

.delete-icon {
  color: $color-danger;
  cursor: pointer;
}

.hidden {
  display: none;
}

.excel-btn {
  color: #02723b;
  @include flex-center();
}

.pdf-btn {
  color: #b30b00;
  @include flex-center();
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 12px;
  }
}
