.selection-metier {
  display: flex;
  justify-content: center;

  .metier-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
    width: fit-content;
    max-width: 1000px;
    gap: 1rem 4rem;
  }

  .metier-card-wrap {
    flex: 0 0 calc((100% - 2 * 4rem) / 3);
    display: flex;
    justify-content: center;
    min-width: fit-content;
  }
}
