@import '/src/styles/global.scss';

$color-primary: rgba($color-primary, 0.8);
$color-background: rgba($color-secondary, 0.4);
$size-bezel: 0.5em;
$size-radius: 4px;
$glass: rgba(255, 255, 255, 0.3);

.fournisseur-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 3fr 1fr;
  gap: 1em;
  grid-auto-flow: row;
  grid-template-areas:
    'fournisseur-card__info fournisseur-card__action'
    'fournisseur-card__info ...';
  color: $color-primary;

  font-size: 0.75em;
  padding: 1em;

  h3,
  h2 {
    color: $color-primary;
  }
}

.fournisseur-card_prospectus {
  grid-area: fournisseur-card_prospectus;
}

.fournisseur-card__info {
  grid-area: fournisseur-card__info;

  .fournisseur-contact {
    font-weight: 500;

    .MuiSvgIcon-root {
      font-size: 1rem;
    }

    li {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .fournisseur-adresse {
    font-style: italic;
  }

  .fournisseur-command {
    margin-top: 1em;
  }
}

.fournisseur-card__action {
  grid-area: fournisseur-card__action;

  .fournisseur-card-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2em;
  }
}

.text-forward {
  font-weight: 500;
  color: red;
}

// .fournisseur-card {
//   display: flex;
//   flex-direction: column;
//   border-radius: 4px;
//   // border: 2px solid $color-primary;
//   // box-shadow: 0.5rem 0.5rem 0 rgba($color-primary, 0.5);
//   color: $color-primary;
//   box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

//   background-color: $color-white;
//   font-size: 0.75em;
//   padding: 1em;

//   font-size: 0.75rem;
//   line-height: normal;

//   .fournisseur-card__content {
//     display: flex;
//     justify-content: space-between;
//   }

//   .fournisseur-card__info-wrap {
//     display: flex;
//     flex-direction: column;
//   }

//   .fournisseur-card__header {
//     @include flex-column-center();
//     ul {
//       @include flex-column-center();
//     }
//   }
//   h3 {
//     font-size: 1.5em;
//   }

//   .fournisseur-card__info {
//     display: flex;
//     flex-direction: column;
//     gap: 1.5rem;
//   }

//   .fournisseur-card__info-fournisseur {
//     display: flex;
//     gap: 1.5rem;
//   }
//   .fournisseur-card__adress {
//     font-weight: 400;
//   }

//   .fournisseur-card__info-command {
//     max-width: 600px;
//     h5 {
//       font-size: 0.9rem;
//     }
//     font-size: 0.85rem;

//     span {
//       font-weight: 500;
//     }
//   }
// }

// .button-list {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 1em;
// }
