@import '/src/styles/global.scss';

.recap_command__container {
  display: flex;
  flex-direction: column;
  gap: 4em;
  width: 100%;
  height: 100%;

  .action-list {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
