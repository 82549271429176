@import '/src/styles/global.scss';

.legislation {
  padding: $padding-page;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .legislation-title {
    width: 100%;
    @include title-horrizontal-bar($color-text, $color-primary);
  }
}
