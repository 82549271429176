@import '/src/styles/global.scss';

.search-bar-input-wrapper {
  position: relative;
  width: fit-content;
  min-width: 150px;
  display: flex;
  align-items: center;
  color: $color-text-muted;

  .search-bar-icon {
    position: absolute;
    left: -1.5em;
    height: 100%;
    display: flex;
    align-items: center;

    .svg {
      position: absolute;
    }
  }

  .search-bar-input {
    padding: 0.25rem;
    font-size: 0.85em;
    border-bottom: 1px solid currentColor;
    color: $color-text-muted;

    &:focus {
      border-color: $color-primary;
    }
  }

  &:focus-within {
    &:not(.search-bar-input) {
      color: $color-primary;
    }
  }
}
