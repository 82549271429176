@import '/src/styles/global.scss';

.ae-prestataire-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: 10px;
  box-shadow: 0 5px 5px #8d8d8d;
  // border: 1px solid $color-accent;
  box-sizing: border-box;
  overflow: hidden;

  .ae-prestataire-card-title {
    padding: 0.5em 1em;
    width: 100%;
    background-color: $color-accent;
  }

  .ae-prestataire-card-sub-title {
    // margin-bottom: 0.5em;
    width: 100%;
    background-color: rgba($color-primary, 0.3);
    text-align: center;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 500;
  }

  .ae-prestataire-card-info {
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    li {
      svg {
        vertical-align: middle;
      }
      a {
        color: $color-complementary;
      }
    }
  }
  .filepdf{
    flex-direction: row;
  }
}

.ae-prestataire-card__header {
  text-align: center;
}
.ae-prestataire-card__section {
  flex: 1;
  border-top: 1px solid $color-primary;
}
