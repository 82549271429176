@import '/src/styles/global.scss';

$color-primary: $color-text;
$color-background: rgba($color-secondary, 0.4);
$size-bezel: 0.5em;
$size-radius: 4px;
$glass: rgba(255, 255, 255, 0.3);

.cl-doc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: fit-content;
  gap: 1em;
  width: 75vw;
  max-width: 450px;

  .justify {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
}
.date-validite {
  display: flex;
  gap: 1em;
}
.cl-doc__action {
  @include flex-center();
}
