@import '/src/styles/global.scss';

$color-primary: rgba($color-primary, 0.8);
$color-background: $color-white;
$size-bezel: 0.5em;
$size-radius: 4px;
$glass: rgba(255, 255, 255, 0.3);

.commande-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.85em;

  .product-img {
    padding: 5px;
    height: 100px;
    @include flex-center;

    img {
      width: auto;
      height: 100%;
      border-radius: 0.5em;
    }
  }

  .select {
    position: relative;
    @include flex-center();

    &__field {
      width: 90px;
      height: 40px;
      box-sizing: border-box;
      display: block;
      border: 1px solid $color-primary;
      padding: calc($size-bezel * 1.5) $size-bezel;
      color: currentColor;
      background: $glass;
      border-radius: $size-radius;
      text-align: right;
      cursor: pointer;

      option {
        color: inherit;
        background-color: $color-background;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .input {
    position: relative;
    @include flex-center();

    &__field {
      height: 40px;
      width: 50px;
      box-sizing: border-box;
      display: block;
      border: 1px solid $color-primary;
      padding: calc($size-bezel * 1.5) $size-bezel;
      color: currentColor;
      background: $glass;
      border-radius: $size-radius;
      text-align: right;
    }
  }

  .text-area {
    position: relative;
    @include flex-center();
    width: 100%;

    &__field {
      width: 100%;
      text-align: left;
      resize: none;
      box-sizing: border-box;
      display: block;
      border: 1px solid $color-primary;
      padding: calc($size-bezel * 1.5) $size-bezel;
      color: currentColor;
      background: $glass;
      border-radius: $size-radius;
    }
  }

  .price-row {
    text-align: right;
    font-weight: 500;
  }

  .code-row {
    text-align: center;
  }
}

.commande-table__head {
  color: $color-white;
  font-weight: 600;
}

.commande-table__body {
  color: rgba($color-primary, 0.8);
  border-bottom: 1px solid rgba($color-primary, 0.2);
  font-size: 1em;
  background-color: rgba(255, 255, 255, 0.3);

  .action {
    text-align: center;
  }
}

.commande-table__head-row {
  background-color: rgba($color-primary, 0.8);
  position: sticky;
  top: 0;
  // z-index: 9999;

  padding: 0.5em 1em;
  min-width: 70px;
  text-align: center;
}

.commande-table__body-row {
  padding: 0.5em 1em;

  .action-trash {
    color: $color-danger;
    cursor: pointer;
  }
}

.commande-table--collapse {
  font-size: 0.85em;
  width: 100%;
  border-collapse: collapse;
  // border-radius: 4px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  .commande-table__head-row {
    background-color: rgba($color-accent, 0.8);
  }
}
