@import '/src/styles/global.scss';

.outlined-link {
  width: 150px;
  padding: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  color: $color-text-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid currentColor;

  &:hover {
    background-color: $color-accent-80;
  }

  &.selected-link {
    background-color: $color-accent;
    box-shadow: 5px 5px 5px #1b1b1b;
  }
}
