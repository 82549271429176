@import '/src/styles/global.scss';

.normal-table-component {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;

  .normal-table-head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    gap: 1rem;
  }

  // .normal-table-filter {
  //   flex-shrink: 0;
  // }
  .normal-table-overlay {
    border-radius: 5px;
    box-shadow: 0 5px 5px #8d8d8d;
    overflow: hidden;
  }

  .normal-table-header {
    position: sticky;
    top: 0;
    flex-shrink: 0;
    padding: 10px;
    background-color: $color-primary;
    color: $color-text-light;
    text-transform: uppercase;
    font-size: 0.85rem;
  }
  .normal-table-wrapper {
    position: relative;
    border-radius: 5px;
    max-height: 700px; /* Ajustez la hauteur maximale selon vos besoins */
    flex-grow: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px; /* largeur de la scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: $color-primary-variant; /* couleur de fond de la scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary; /* couleur du "pouce" de la scrollbar */
      border-radius: 5px; /* arrondi des coins du "pouce" */
    }
  }

  .input-filter {
    padding: 0.5em 0.5em 0.5em 1em;
    border-radius: 4px;
  }

  .normal-table-table {
    font-size: 0.75rem;
    width: 100%;

    // overflow: hidden;
    .normal-table-td2 {
      background-color: $color-primary-10;
    }

    .normal-table-numeric {
      text-align: right;
    }

    .normal-table-center {
      text-align: center;
    }

    .normal-table-icon {
      padding: 0;
      text-align: center;
    }

    td,
    th {
      border: 1px solid #ddd;
      padding: 0.1rem 1rem;

      .italique {
        font-style: italic;
      }

      .gras {
        font-weight: 700;
      }
    }

    .normal {
      background-color: $color-secondary;
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
