@import '/src/styles/global.scss';

.photo-text-card {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  box-shadow: $box-shadow-card;
  position: relative;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &__photo {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.5em;
    background-color: $color-secondary;
    color: $color-text;
    text-align: center;
  }
}
