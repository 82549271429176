@import '/src/styles/global.scss';

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;

  .home-infos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    .home-infos-droite {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .info-banner {
    padding: 1rem 2rem;
    border-radius: 4px;
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    cursor: pointer;
    background-color: $color-referencia;

    color: $color-text-light;
    font-size: 20px;
    font-weight: 500;

    .info-banner-action {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }

  .link-fill {
    padding: 0.5rem 0.5rem;
    background-color: $color-accent;
    color: $color-text-light;
    height: fit-content;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

/* Au niveau le plus haut du code */
@media screen and (max-width: 1024px) {
  .info-banner {
    > img {
      max-width: 250px;
    }
  }
}
