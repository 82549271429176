@import '/src/styles/global';

.contact-page {
  padding: $padding-page;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;


  .contact-page-title {
    width: 100%;
    @include title-horrizontal-bar($color-text, $color-primary);
  }
  .contact-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .service-card{
    display: flex;
    align-items: stretch;
    width: 100%;

    justify-content: center;

    li {
      svg {
        vertical-align: middle;
      }

      a {
        color: $color-complementary;
      }
    }


    .service-card-gauche {
      display: flex;
      width: 350px;
      box-sizing: border-box;
      padding: 10px;
      position: relative;
      align-items: center;

      &::after {
        content: " ";
        position: absolute;
        height: 95%;
        width: 3px;
        right: 1em;
        background-color: $color-primary;
      }

    }
    .service-title{
      text-transform: uppercase;
      font-size: $font-size-title;
    }

    .service-card-droite {
      display: flex;
      flex-grow: 1;
      box-sizing: border-box;
      padding: 10px;

    }


  }
}