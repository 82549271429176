@import '/src/styles/global.scss';

.contrat-carousel-wrap {
  display: flex;
  justify-content: center;

  .card {
    border-radius: 0.75em;
    // cursor: pointer;
    width: 760px;
    height: 160px;
    position: relative;
  }

  .card:before {
    background: linear-gradient(
      to right bottom,
      transparent 25%,
      $color-primary 50%,
      transparent 75%
    );

    border-radius: inherit;
    content: '';
    inset: 0px;
    position: absolute;
    z-index: 0;
  }

  .card-content {
    border-radius: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 3px;
    padding: 1em;

    background-color: $color-background;
    position: absolute;
  }

  swiper-container {
    position: relative;
    border-radius: inherit;
    width: 560px;
    height: 160px;

    .swiper-contrat-slide {
      position: relative;
      height: 100%;
      max-width: 140px;
    }

    .image-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1em;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

/* Au niveau le plus haut du code */
@media screen and (max-width: 1024px) {
  .contrat-carousel-wrap {
    display: none;
  }
}
