@import '/src/styles/global';

$radius: 4px;

@mixin color-btn(
  $color-background,
  $color-text,
  $color-background-hover,
  $color-text-hover
) {
  span {
    color: $color-text;
  }
  poisition: relative;
  background: $color-background;
  border: 2px solid $color-text;

  &:hover:not([disabled]) {
    &::after {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, 0.05);
    }
    span {
      color: $color-text-hover;
    }
  }
}

.button-sharp {
  position: relative;
  max-height: 1.5em;
  width: fit-content;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.24px;
  overflow: hidden;
  text-transform: uppercase;

  cursor: pointer;
  span {
    font-size: 0.9em;
  }
  &[disabled] {
    box-shadow: inset 0 0 10rem rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }

  &.color-primary {
    @include color-btn(
      $color-accent,
      $color-primary,
      $color-button,
      $color-primary
    );
  }

  &.color-secondary {
    @include color-btn(
      $color-white,
      $color-primary,
      rgba($color-primary, 0.2),
      $color-primary
    );
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    z-index: 2;
    transition: 0.5s ease-in-out;
    white-space: wrap;
    opacity: 1;
    transition: opacity 0s ease-in-out;
  }

  .loading {
    opacity: 0;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
