@import '/src/styles/global.scss';

.form-control-overlay {
  position: relative;
  display: flex;
  flex-direction: column;

  .form-control {
    position: relative;

    .icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding-right: 0.75em;
      display: flex;
      align-items: center;
    }

    > *:not(.icon) {
      padding: 5px 12px;
      border: 1px solid $color-border-input;
      color: currentColor;
      background-color: $color-background-input;
      border-radius: $radius-input;
      line-height: 20px;
      vertical-align: middle;
      width: 100%;
      font-size: 14px;

      &:not(textarea) {
        height: calc(1.7rem + 5px);
      }

      &::placeholder {
        color: $color-text-muted;
      }

      &:focus {
        background-color: transparent;
      }
    }

    &:not(.form-control--error) {
      > *:focus:not(.icon) {
        box-shadow: rgba($color-primary, 1) 0px 0px 0px 1.5px;
      }
    }

    &--error {
      > * {
        box-shadow: rgba($color-danger, 0.5) 0px 0px 0px 1.5px;
      }
    }

    input[type='password'] {
      padding-right: 2rem;
    }

    > select {
      cursor: pointer;
    }

    > textarea {
      resize: none;
    }
  }

  .label {
    font-weight: 500;
    color: $color-text-title;
  }

  .error {
    color: $color-danger;
  }
}
