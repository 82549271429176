@import '/src/styles/global';

.newsletter {
  padding: $padding-page;
  max-width: 1024px;
  margin: auto;

  .newsletter-header {
    display: flex;
    justify-content: center;

    .container-title {
      all: inherit;
      margin-bottom: 25px;
      padding: 10px 25px 15px 25px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      text-align: center;
      align-content: center;
      flex-direction: row;
      background-color: $color-primary;
      border-radius: 2% 2% 100% 100%;

      > .logo {
        height: 50px;

        > img {
          vertical-align: middle;
        }
      }

      > .title {
        all: inherit;
        max-height: 40px;
        font-size: 30px;
        text-transform: uppercase;
        color: $color-text-light;
        font-weight: bold;
      }
    }
  }

  .newsletter-content {
    display: flex;
    gap: 2rem;
  }

  .newsletter-historique {
    padding: 1rem 1.5rem 2rem 1.5rem;
    border-radius: 2% 2% 100% 100%;
    height: fit-content;
    background-color: #bfc734;
    width: fit-content;

    > ul {
      width: 100%;

      li {
        text-align: center;
        font-weight: 500;
        font-size: 1.25rem;
        color: $color-primary;

        &:hover {
          color: $color-text-light;
          cursor: pointer;
        }
      }
    }
  }

  .newsletter-actus {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border: solid $color-primary-variant;
    border-radius: 3px;
    border-width: 4px 0px;
    background-color: $color-primary-10;
  }

  .newsletter-actus-card {
    > img {
      width: 300px;
      height: 300px;
      border-radius: 4px;
    }
  }
}
