@import '/src/styles/global.scss';

.merged-table-overlay {
  border-radius: 5px;
  box-shadow: $box-shadow-medium;
  max-width: 100%;
  width: 850px;

  .merged-table-wrapper {
    position: relative;
    border-radius: 5px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px; /* largeur de la scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: $color-primary-variant; /* couleur de fond de la scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary; /* couleur du "pouce" de la scrollbar */
      border-radius: 5px; /* arrondi des coins du "pouce" */
    }
  }

  .merged-table {
    font-size: 0.75rem;
    width: 100%;

    thead > tr {
      position: sticky;
      top: 0;
      flex-shrink: 0;
      padding: 10px;
      background-color: $color-primary;
      color: $color-text-light;
      text-transform: uppercase;
      font-size: 0.85rem;
    }

    tr {
      td,
      th {
        border: 1px solid $color-border-cell-table;
        border-bottom: none;
        padding: 0.1rem 1rem;

        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
      }

      th {
        border-top: none;
      }
    }

    .center-align {
      text-align: center;
    }

    .text-primary {
      color: $color-primary;
    }
  }
}
