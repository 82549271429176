@import '/src/styles/global.scss';

$color-btn: $color-accent;

.pdf-command-btn {
  padding: 0.75em 2.5em;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  @include btn($color-white, $color-btn, $color-btn, $color-white);
  border-color: $color-btn;

  svg {
    height: 1.5em;
    width: fit-content;
  }
  padding-left: 1em;
  padding-right: 1em;
}
