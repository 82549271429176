@import '/src/styles/global.scss';

$img-height: 200px;

.book-card {
  position: relative;
  display: inline-block;

  &__img {
    max-height: 200px;
    z-index: -1;
  }
  .book-links {
    position: absolute;

    padding: 0.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    background-color: rgba($color-black, 0.7);
    min-height: 75%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    text-align: center;
    color: $color-text-light;

    & .title {
      padding: 1em;
      font-size: 11px;
    }
  }

  .book-link {
    width: fit-content;
    &:hover {
      color: $color-text-light;
    }
  }
}
