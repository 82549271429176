@import '/src/styles/global.scss';

.profile {
  padding: $padding-page;

  .profile-forms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    gap: 1.5rem; /* Espacement horizontal entre les formulaires */

    > * {
      width: 100%;
      max-width: 700px;
      flex: 1 0 350px;
    }
  }
}
