@import '/src/styles/global.scss';

$color-title-text: #000;
$color-title-bar: $color-complementary;

.cl-select-opt {
  margin: 2rem 0;
  .cl-select-opt__content {
    h3 {
      margin: 4em 0;
      @include title-horrizontal-bar(
        $color-title-text,
        $color-title-bar
      );
    }
  }
  .cl-select-opt__option-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4em;

    .cl-option {
      border-radius: 5px;
      width: 250px;
      height: 250px;
      box-shadow: 5px 5px 5px #3d3d3d;
      overflow: hidden;
      cursor: pointer;
      color: $color-text;

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 70% 30%;
      gap: 0px 0px;
      grid-template-areas:
        'cl-option__img'
        'cl-option__title';
    }

    .cl-option__img {
      grid-area: cl-option__img;
    }
    .cl-option__title {
      grid-area: cl-option__title;
      @include flex-center();
      text-transform: uppercase;
      font-weight: 500;
      background-color: $color-secondary;
      text-align: center;
    }
  }
}
