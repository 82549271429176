@import '/src/styles/global.scss';
.articles {
  display: flex;
  flex-direction: column;
  padding: $padding-page;

  .articles-title {
    width: 100%;
    @include title-horrizontal-bar($color-text, $color-primary);
  }
}
