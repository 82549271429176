@import '/src/styles/global.scss';

$color-primary: rgba($color-primary, 0.8);
$color-primary-variant: $color-white;
$color-background: rgba($color-secondary, 0.4);
$color-info: $color-danger;
$border-radius: 4px;
$box-shadow: $box-shadow-card;
$size-bezel: 0.5em;
$size-radius: 4px;

.bdc-cft {
  @include flex-column-center();

  *:first-child {
    margin-top: 0;
  }

  h3 {
    @include title-horrizontal-bar($color-primary, $color-secondary);
  }
}

.bdc-cft__main {
  padding: 2em;
  background-color: $color-white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.bdc-cft-table {
  border-collapse: collapse;
  font-size: 0.85em;
}

.bdc-cft-table__row {
  height: fit-content;
  td,
  th {
    color: $color-primary;
    padding: 0.5em 1em;
    height: fit-content;
  }

  th {
    text-transform: uppercase;
  }

  .action {
    // position: absolute;
    // min-width: fit-content; // height: 100%;
    // width: 100%;
    @include flex-center();
    gap: 1em;
    width: fit-content;
    font-size: 0.85em;
  }
  .action-head {
    width: fit-content;
  }
  .number {
    text-align: right;
    max-width: 100px;
    font-size: 0.85em;
    // text-overflow: ellipsis;
  }
  .franco {
    text-align: right;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 250px;
    font-size: 0.85em;
  }

  .date {
    text-align: center;
    max-width: 300px;
    color: $color-danger;
    font-weight: 500;
    font-size: 0.85em;
  }
  .string {
    max-width: 300px;
    font-weight: 500;
    font-size: 1.1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::-webkit-scrollbar-track {
    background-color: (rgba($color-black, 0.2));
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba($color-primary, 0.5);
  }
}
