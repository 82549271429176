@import '/src/styles/global.scss';

$color-primary: rgba($color-primary, 0.8);
$color-primary-variant: $color-white;
$color-background: $color-secondary;
$color-info: $color-danger;
$border-radius: $border-radius-card;
$box-shadow: $box-shadow-card;

.prospectus-card {
  display: flex;
  height: 400px;
  overflow: hidden;
  flex-direction: column;
  background-color: $color-white;
  border-radius: 4px;
  // border: 2px solid currentColor;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  // box-shadow: 0.5rem 0.5rem 0 rgba($color-primary, 0.5);
  color: $color-primary;

  .prospectus-card__header {
    height: 23%;
    width: 100%;
    background-color: $color-primary;
    color: $color-primary-variant;

    h4 {
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: $color-primary-variant;
      padding: 5px 10px;
    }
    // .mois {
    //   height: 20%;
    //   text-align: right;
    //   font-size: 0.65rem;
    //   padding: 0.25rem 0.5rem;
    // }
  }

  .content {
    height: 37%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .info {
      width: 100%;
      height: 50px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 500;
      background-color: rgba(white, 0.5);
      color: $color-info;
    }

    .date-list {
      flex: auto;
      padding: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 500;
      }
      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
      }
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  footer {
    height: 40%;
    display: flex;
    flex-direction: column;

    .action-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 7px;
      height: 100%;
      width: 100%;
      padding: 15px;
    }

    .action-button {
      padding: 0.5em 2em;
      cursor: pointer;
      font-size: 12px;
      width: 250px;
      @include btn(
        $color-primary,
        transparent,
        $color-primary-variant,
        $color-primary
      );
      border-color: $color-primary;
    }

    .primary-button {
      background-color: $color-primary;
      color: $color-primary-variant;
    }

    .action-icon {
      margin-top: 0.25em;
      @include flex-center;
      gap: 1rem;
    }

    .action-link {
      @include flex-column-center();
      font-size: 0.65em;
    }
  }
}
