@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin title-horrizontal-bar($color-title-text, $color-title-bar) {
  margin-bottom: 1.5rem;
  width: 100%;
  height: fit-content;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: $color-title-text;

  display: flex;
  align-items: center;
  gap: 2rem;

  &:before,
  &:after {
    content: ' ';
    flex: 1;
    position: relative;

    height: 2px;
    background-color: $color-title-bar;
    width: 100%;
    opacity: 80%;
    min-width: 25px;
  }
}

@mixin select-with-label(
  $size-bezel,
  $size-radius,
  $color-text,
  $color-border,
  $color-background
) {
  position: relative;

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    padding: calc($size-bezel * 0.75) calc($size-bezel * 0.5);
    margin: calc($size-bezel * 0.75 + 3px) calc($size-bezel * 0.5);
    white-space: nowrap;
    transform-origin: 0 0;
    transform: translate(0.25em, -65%) scale(0.8);
    background-image: linear-gradient(
      to bottom,
      transparent 0 calc(40% - 2px),
      $color-background calc(40% - 2px)
    );
    color: $color-text;
    transition: transform 120ms ease-in;
    font-weight: bold;
    line-height: 1.2;
  }

  &__field {
    min-width: fit-content;
    height: fit-content;
    max-height: fit-content;
    box-sizing: border-box;
    display: block;
    border: 1px solid $color-border;
    padding: calc($size-bezel * 1.5) $size-bezel;
    color: $color-text;
    background: $color-background;
    border-radius: $size-radius;
    cursor: pointer;

    option {
      color: inherit;
      background-color: $color-background;
    }

    &:focus {
      outline: none;
    }
  }
}

@mixin input-with-label(
  $size-bezel,
  $size-radius,
  $color-text,
  $color-border,
  $color-background
) {
  position: relative;

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc($size-bezel * 0.75) calc($size-bezel * 0.5);
    margin: calc($size-bezel * 0.75 + 3px) calc($size-bezel * 0.5);
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background-image: #ffe2b6;
    color: $color-text;
    transition: transform 120ms ease-in;
    font-weight: bold;
    line-height: 1.2;
    cursor: text;
  }
  &__field {
    height: fit-content;
    max-height: fit-content;
    box-sizing: border-box;
    display: block;
    border: 1px solid $color-border;
    padding: calc($size-bezel * 1.5) $size-bezel;
    color: $color-text;
    background: $color-background;
    border-radius: $size-radius;

    &:focus,
    &:not(:placeholder-shown) {
      & + span {
        transform: translate(0.25em, -65%) scale(0.8);
        background-image: linear-gradient(
          to bottom,
          transparent 0 calc(40% - 2px),
          $color-background calc(40% - 2px)
        );
        color: $color-primary;
      }
    }
  }
}

@mixin textarea-with-label(
  $size-bezel,
  $size-radius,
  $color-text,
  $color-border,
  $color-background
) {
  position: relative;

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc($size-bezel * 0.75) calc($size-bezel * 0.5);
    margin: calc($size-bezel * 0.75 + 3px) calc($size-bezel * 0.5);
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background-image: #ffe2b6;
    color: $color-text;
    transition: transform 120ms ease-in;
    font-weight: bold;
    line-height: 1.2;
  }

  &__field {
    resize: none;
    height: fit-content;
    max-height: fit-content;
    box-sizing: border-box;
    display: block;
    border: 1px solid $color-border;
    padding: calc($size-bezel * 1.5) $size-bezel;
    color: $color-text;
    background: $color-background;
    border-radius: $size-radius;

    & + span {
      transform: translate(0.25em, -65%) scale(0.8);
      background-image: linear-gradient(
        to bottom,
        transparent 0 calc(40% - 2px),
        $color-background calc(40% - 2px)
      );
      color: $color-primary;
    }
  }
}

@mixin btn(
  $color,
  $color-background,
  $color-hover,
  $color-background-hover
) {
  position: relative;
  @include flex-center();
  gap: 0.5em;
  overflow: hidden;
  text-transform: uppercase;
  // line-height: 1;
  border: 1px solid currentColor;
  color: $color;
  background-color: $color-background;
  z-index: 1;
  border-radius: 4px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-background-hover;
    transform: translateX(-100%);
    z-index: -1;
  }

  &:hover {
    // background-color: transparent;
    color: $color-hover;
    transition: all 0.5s ease-in-out;

    &:before {
      transform: translateX(0);
      transition: all 0.5s ease-in-out;
    }
  }
}

@mixin modal($width, $height) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $width;
  height: $height;
  border-radius: 10px;
  overflow: hidden;
}
