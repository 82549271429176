@import '/src/styles/global.scss';

.accueil-ope-card {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  width: fit-content;

  .accueil-ope {
    flex: 1 0 200px;

    border-radius: 5px;
    max-width: 200px;
    box-shadow: 0 5px 5px #8d8d8d;
    box-sizing: border-box;
    overflow: hidden;

    .accueil-ope-header {
      text-align: center;
      padding: 0.5em 1em;
      background-color: $color-primary;
      color: $color-background;
    }

    .accueil-ope-img img {
      display: block;
      width: 100%;
    }
  }
}
