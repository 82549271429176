@import '/src/styles/global.scss';

.salon-inscription-modal {
  font-size: 0.85em;

  > p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: $color-warning;
  }
}
