@import '/src/styles/global.scss';

.articles-table {
  .articles-filtre {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 2rem;
    gap: 1rem;

    .filters-wrap {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    > * {
      flex: 1 1 500px;
    }

    > :first-child {
      flex: 1 1 500px;
    }

    .duo-filters {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      > :first-child {
        flex: 1 0 300px;
      }

      > :last-child {
        flex: 1 0 150px;
      }
    }
    .horizontal-filters {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      > * {
        flex: 1 0 150px;
      }
    }

    .filter-search-btn {
      margin-top: 1rem;
      flex: 1 0 100%;
      height: fit-content;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
  .articles-filtres-listes {
    display: flex;
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltip-text {
    visibility: hidden;
    width: 160px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  .pagination {
    margin-top: 1rem;
    color: $color-text-title;
    font-weight: 500;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }
}
@media screen and (max-width: 600px) {
  .articles-filtre {
    .duo-filters {
      &::after {
        content: '';
        display: block;
        flex: 1 0 150px;
      }
    }

    .horizontal-filters {
      &::after {
        content: '';
        display: flex;
        flex: 1 0 150px;
      }
      .filter-search-btn {
        order: 1;
        display: flex;
      }
    }
  }
}
