@import '/src/styles/global.scss';

.title-row-table-wrapper {
  border-radius: 5px;
  box-shadow: 0 5px 5px #8d8d8d;
  overflow: auto;

  .title-row-table {
    font-size: 0.75rem;

    // overflow: hidden;

    td,
    th {
      border: 1px solid #ddd;
      padding: 0.1rem 1rem;

      .italique {
        font-style: italic;
      }

      .gras {
        font-weight: 700;
      }
    }

    .head-row {
      background-color: $color-primary;
      color: $color-text-light;
      text-transform: uppercase;
      font-size: 0.85rem;
    }

    //   .row {
    //     &:hover {
    //       background-color: #ddd;
    //     }
    //   }

    .title-row {
      background-color: $color-secondary;
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
