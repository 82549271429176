@import '/src/styles/global.scss';

$btn-width: 250px !default;
.return-btn {
  margin-bottom: 1rem;
  .btn__shell {
    cursor: pointer;
    display: flex;
    gap: 0.5em;
    @include btn(
      $color-primary,
      transparent,
      $color-primary,
      transparent
    );
    padding: 0.5em 1em;
    max-width: $btn-width;
    font-size: 0.75em;
  }
}
