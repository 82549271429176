@import '/src/styles/global.scss';

$color-title-text: $color-black;
$color-title-bar: $color-primary;

.select-magasin-wrapper {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .select-magasin-head {
    padding: 0.5rem;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      display: block;
      height: 30px;
    }
  }
  .select-magasin {
    flex: 1;
    padding: $padding-page;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    overflow: hidden;
  }
  .select-magasin-title {
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    max-width: 700px;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: $color-text-title;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 1rem;

    &:before,
    &:after {
      content: ' ';
      position: relative;
      top: 50%;
      display: block;
      background-color: $color-primary-variant;
      height: 2px;
      min-width: 25px;
    }
  }

  .select-magasin-card-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 1em;
    align-items: stretch;
    // text-align: center;
  }
}
