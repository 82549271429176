@import '/src/styles/global.scss';

.choix-tarif-modal {
  > Button {
    font-size: 0.75rem;
    color: $color-primary-variant;
  }
}

.tarif-modal {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > h3 {
    text-align: center;
    font-weight: 700;
  }

  .select-tarif {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    gap: 1rem;

    .select-tarif__field {
      padding: 1rem;
      display: flex;
      text-align: center;
      gap: 1rem;
      border: 1px solid $color-border-input;
      border-radius: 4px;
      background-color: #fafafa;
      cursor: pointer;
    }

    .select-tarif__field--selected {
      background-color: rgba($color-primary-variant, 0.3);
      border-color: $color-primary-variant;
    }
  }

  .select-tarif__action {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
  }
}
