@import '/src/styles/global.scss';

.cl-dispe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: fit-content;
  gap: 1em;
  width: 75vw;
  max-width: 450px;

  .justify {
    display: flex;
    gap: 1em;
  }
}
.cl-dispe__action {
  @include flex-center();
}

.reference-palette {
  margin-left: 2rem;
}
