@import '/src/styles/global.scss';

.mdg-telechargements-page {
  padding: $padding-page;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mdg-telechargements-page-title {
    width: 100%;
    @include title-horrizontal-bar($color-text, $color-primary);
  }
}