@import '/src/styles/global.scss';

.select-info-card {
  position: relative;
  overflow: hidden;
  // padding: 1rem;
  height: 50px;
  min-height: fit-content;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 16px;
  box-shadow: $box-shadow-card;
  color: $color-text;
  background-color: transparent;
  font-weight: 500;

  .card-background {
    position: absolute;
    top: 0; // Positionnement vertical au milieu
    left: 0; // Positionnement horizontal au milieu
    transform: translate(-50%, -50%); // Recentrage du point d'origine
    width: 300%;
    height: 300%;
    border-radius: 50%;
    background-color: $color-secondary;
    z-index: -1;
    // transition: width 0.5s ease, height 0.5s ease; // Transition pour l'animation
  }
  .card-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    bottom: 0;
    right: 0;
    z-index: -2;
  }
}
