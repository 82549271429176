@import '/src/styles/global.scss';

.drawer-overlay {
  position: relative;
  min-width: 55px;
  height: 100%;
  background-color: $color-complementary;
}
.drawer {
  position: relative;
  height: 100%;
  min-width: fit-content;
  font-size: 0.85em;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: $color-complementary;

  &__head {
    padding: 1rem;
    width: 100%;
    color: $color-text-light;
  }

  &__content {
    padding-top: 10%;
    padding-bottom: 10%;
    width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .drawer--open {
    position: absolute;
    right: 0;
  }
}
