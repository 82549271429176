.selection-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .select-info-cards {
    display: flex;
    gap: 1rem;
    justify-content: center;

    > * {
      flex: 0 0 200px;
      width: 200px;
    }
    flex-wrap: wrap;

    &::after {
      content: '';
      display: block;
      flex: 0 0 200px;
    }
  }
}
