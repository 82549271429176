@import '/src/styles/global.scss';
$max-width: 700px;

.salon-formulaire {
  display: flex;
  flex-direction: column;
  align-items: center;

  .contact {
    display: flex;
    width: 100%;
    padding: 15px 0px;
    flex-direction: column;
    align-items: center;
    background-color: $color-primary-10;

    .title {
      color: $color-primary;
      font-size: 32px;
      font-weight: 800;
    }

    .contact-inputs {
      padding: 15px 30px;
      width: 100%;
      max-width: $max-width;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }

  .radio-wrap {
    margin-top: 65px;
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    gap: 1rem;

    .radio {
      display: flex;
      gap: 1rem;
    }

    .radio-option {
      > * {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

  .participation {
    .info-participation {
      color: $color-primary;

      > h4 {
        font-size: 20px;
        font-weight: 600;
      }

      > p {
        padding: 0.625rem;
        background-color: $color-primary-10;
        max-width: 680px;
        font-size: 16px;
      }
    }

    .info-non-participation {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      > p {
        margin-bottom: 1rem;
      }

      ul > li {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .table-participants {
    width: 100%;
    margin: 2rem 0 0 0;

    > thead > tr > th {
      color: $color-disabled;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }

    tr {
      border-bottom: 1px solid #eee;
    }

    td,
    th {
      padding: 1px 14px;
    }

    > tbody > tr > td {
      text-align: left;
      color: $color-text;
      font-size: 14px;
    }

    .checkbox-cell {
      text-align: center;

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .submit {
    margin: 4rem 0;
    display: flex;
    justify-content: center;
  }
}
.dossier-salon-info {
  padding: 40px 0;
  background-color: $color-referencia;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 50px;

  .info {
    color: $color-text-light;
    // max-width: 720px;
    font-size: 14px;
    font-weight: 600;
  }
}
