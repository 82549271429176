.spin-loader-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: block;
}

.basic-spinner {
  height: 25px;
  width: 25px;
  left: 50%;
  position: absolute;
  top: 50%;

  animation: full-rotation 1s linear infinite;
  animation-direction: normal;
  background-image: url(./site-spinner-240-light.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  height: 1.5em;
  margin: 0 auto;
  opacity: 1;
  width: 1.5em;
}

@keyframes full-rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}
// @-webkit-keyframes nfLoader-spin {
//   100% {
//     -webkit-transform: rotate(360deg);
//   }
// }
