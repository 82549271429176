@import '/src/styles/global.scss';

.articles-sup{

  padding: $padding-page;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .articles-sup-title {
    width: 100%;
    @include title-horrizontal-bar($color-text, $color-primary);
  }
  .article-sup-table{



  }

}
