@import '/src/styles/global.scss';

.auth {
  // margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $padding-page;
  // justify-content: center;
  gap: 5%;
  height: 100vh;
  width: 100vw;
  background-color: $color-background-login;

  p {
    color: $color-text-light;
  }

  > img {
    max-height: 43%;
    max-width: 100%;
  }

  h3 {
    margin-bottom: 1em;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.1em;
    text-align: center;
    color: $color-text-light;
  }

  .form-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    gap: 0.5em;

    a {
      color: $color-link;
      font-weight: 700;
      font-size: 0.75em;

      &:hover {
        color: $color-link-hover;
      }
    }
  }

  .form-inputs {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .form-error {
    color: $color-danger;
    font-weight: 700;
    font-size: 0.75em;
    text-align: center;
  }

  .mail-sent-message {
    color: $color-primary;
  }
}
