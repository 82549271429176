@import '/src/styles/global.scss';

.search-input-wrapper {
  position: relative;
  //   padding: 0 1rem;
  border: 1px solid $color-primary;
  width: fit-content;
  min-width: 150px;
  border-radius: 18.5px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .search-icon {
    position: relative;
    background-color: rgba($color-primary, 0.1);
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    color: $color-primary;

    .svg {
      position: absolute;
    }

    &::after {
      content: ' ';
      position: absolute;
      background-color: $color-primary;
      width: 1px;
      height: 100%;
      right: 0px;
    }
  }

  .search-input {
    padding: 0.25rem;
    margin-right: 1rem;
    padding-left: 1rem;
    font-size: 0.85em;
  }
}
