@import '/src/styles/global.scss';
$color-title-text: $color-black;
$color-title-bar: $color-primary;

.mdg-page-selection {
  padding: $padding-page;

  .mdg-page-selection-title{
    @include title-horrizontal-bar(
                    $color-title-text,
                    $color-title-bar
    );
  }
  .mdg-page-selection-list{
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, max(350px));

    grid-gap: 6em 2em;
    justify-content: center;
    max-width: 100%;
  }
}