@import '/src/styles/global';

.mises-a-jour{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:$padding-page;

  .mises-a-jour-title {
    width: 100%;
    @include title-horrizontal-bar($color-text, $color-primary);
  }
  .mises-a-jour-conteneur {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Espacement entre les colonnes */
  }

  .mises-a-jour-conteneur > * {
    flex: 1 0 300px; /* Largeur des colonnes */
  }
}