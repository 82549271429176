@import '/src/styles/global.scss';

.cl-devis-dispe-modal {
  font-size: 0.85em;

  .cl-devis-dispe-card {
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    .cl-devis-dispe-card__title {
      color: $color-warning;
      // font-weight: 500;
      font-size: 1.25em;
      text-align: center;
    }
    .cl-devis-dispe-card__info {
      padding: 1em;
      display: flex;
      flex-direction: column;
      line-height: 1.7;
      align-items: center;
      border: 1px dashed rgba($color-secondary, 0.5);

      span {
        color: $color-text;
      }
      .sub-text {
        color: $color-warning;
        font-size: 0.75em;
      }
    }
  }
}
